<script lang="ts">
  import './mobile-menu-button.scss';

  export let isMobileMenuOpen = false;
</script>

<div class="relative overflow-hidden h-6 w-6" class:open={isMobileMenuOpen}>
  <div class="absolute inset-0 flex flex-col justify-around">
    <div class="line-1" />
    <div class="line-2" />
    <div class="line-3" />
  </div>

  <div class="absolute inset-0 flex items-center justify-center">
    <div class="absolute line-4" />
    <div class="absolute line-5" />
  </div>
</div>
