<script lang="ts">
  import './navbar.scss';
  import type { NavLink } from '$lib/builder-components/builder-io.models';

  export let navbarLinks: NavLink[] = [];
  // export let isMobileMenuOpen: boolean;
  export let closeMobileMenu: () => void;
</script>

{#each navbarLinks as link}
  {#if !link.isButton}
    <a
      href="{link.routerLink}#{link.fragment}"
      class="text-blue-grey hover:bg-black-10 button"
      on:click={closeMobileMenu}
    >
      <!--      <span class="text-yellow">.</span>-->

      <div class="relative">
        <div class="animate-line" />
        {link.label}
      </div>
    </a>
  {:else}
    <a
      href="{link.routerLink}#{link.fragment}"
      class="button button-flat"
      on:click={closeMobileMenu}
    >
      .{link.label}
    </a>
  {/if}
{/each}
