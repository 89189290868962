<script lang="ts">
  import type { NavLink } from '$lib/builder-components/builder-io.models';

  export let footerLinks: NavLink[] = [];
  const currentYear = new Date().getFullYear();
</script>

<div class="pb-4 pt-8">
  <div class="container">
    <div class="flex flex-wrap-reverse justify-between gap-4">
      <p class="header-6">© {currentYear} Suayip Uzun</p>
      <div class="flex gap-4">
        {#each footerLinks as link}
          <a class="header-6 text-decoration" href={link.routerLink}>{link.label}</a>
        {/each}
      </div>
    </div>
  </div>
</div>
