<script lang="ts">
  import { browser } from '$app/environment';
  import type { NavLink } from '$lib/builder-components/builder-io.models';
  import logo from '$lib/logo.svg';
  import MobileMenuButton from './mobile-menu-button/MobileMenuButton.svelte';
  import './navbar.scss';
  import NavLinks from './NavLinks.svelte';

  export let navbarLinks: NavLink[] = [];
  let isMobileMenuOpen = false;

  let scrollY: number;

  function closeMobileMenu() {
    isMobileMenuOpen = false;
  }
  function toggleMobileMenu() {
    isMobileMenuOpen = !isMobileMenuOpen;
  }
  $: {
    if (browser) {
      document.body.style.overflow = isMobileMenuOpen ? 'hidden' : 'auto';
    }
  }

  let innerWidth: number;
  let innerHeight: number;

  $: if (innerWidth || innerWidth) {
    isMobileMenuOpen = false;
  }
</script>

<svelte:window bind:scrollY bind:innerWidth bind:innerHeight />

<div class="prepare-animation fixed z-50 w-full py-4" class:shrink={scrollY > 50}>
  <div class="container">
    <div class="flex justify-between">
      <div class="flex items-center animate-logo">
        <!--        <a routerLink="/" [skipLocationChange]="false" (click)="isMobileMenuOpen = false">-->
        <a class="w-full h-full" href="/" on:click={closeMobileMenu}>
          <img src={logo} alt="logo" />
        </a>
      </div>
      <div class="flex items-center gap-4 max-lg:hidden">
        <NavLinks {navbarLinks} {closeMobileMenu} />
      </div>
      <div class="flex items-center lg:hidden">
        <button aria-label="Menu Button" on:click={toggleMobileMenu}>
          <MobileMenuButton {isMobileMenuOpen} />
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class:open={isMobileMenuOpen}
  class="mobile-menu fixed inset-0 z-40 flex flex-col items-center justify-center gap-2 bg-blue-black lg:hidden"
>
  <NavLinks {navbarLinks} {closeMobileMenu} />
</div>
