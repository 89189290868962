import { dev } from '$app/environment';
import type { NavLink } from '$lib/builder-components/builder-io.models';
import type { BuilderContentVariation } from '@builder.io/sdk';
import { builder } from '@builder.io/sdk';
import { _BUILDER_PUBLIC_API_KEY } from './[...path]/+page';

export const prerender = true;
export const trailingSlash = 'always';
export const csr = true;

export async function load(): Promise<LayoutLoadData> {
  builder.canTrack = dev;

  const navbarData: BuilderContentVariation = await builder.get('navigation-bar', {
    apiKey: _BUILDER_PUBLIC_API_KEY
  });
  const footerData: BuilderContentVariation = await builder.get('footer', {
    apiKey: _BUILDER_PUBLIC_API_KEY
  });
  const navbarLinks: NavLink[] = navbarData.data?.['navLinks'] || [];
  const footerLinks: NavLink[] = footerData.data?.['links'] || [];

  return { navbarLinks, footerLinks };
}

export interface LayoutLoadData {
  navbarLinks: NavLink[];
  footerLinks: NavLink[];
}
